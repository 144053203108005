import React from 'react';
import { Form, Button, Input } from 'react-bootstrap';

function SubscribeForm(props) {
  return (
    <div id="mc_embed_signup">
      <center>
      <form
      action="https://gmail.us5.list-manage.com/subscribe/post?u=6aea80f0deb9596a15046f8b9&id=4b2d2599ea&f_id=000784e9f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self">
        <div id="mc_embed_signup_scroll" style={{"display": "inline"}}>
          <div className="mc-field-group" style={{"display": "inline"}}>
            <label htmlFor="mce-EMAIL" className="me-2">
              Get daily news: 
            </label>
            {/* <input
              type="email"
              defaultValue=""
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              required=""
              placeholder='Your email address'
            /> */}
            <Form.Control type="email" placeholder="Enter email" name="EMAIL" id="mce-EMAIL" required="" style={{"display": "inline", "width": "auto"}} className="d-inline my-2 me-2" />
            <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
          </div>
          <div hidden="true" style={{"display": "inline"}}>
            <input type="hidden" name="tags" defaultValue={4166189} />
          </div>
          <div id="mce-responses" className="clear" style={{"display": "inline"}}>
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            />
          </div>
          <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
            <input
              type="text"
              name="b_6aea80f0deb9596a15046f8b9_4b2d2599ea"
              tabIndex={-1}
              defaultValue=""
            />
          </div>

            <Button
              type="submit"
              defaultValue="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
              size="m"
              variant="dark"
              style={{"marginBottom": "0.4em"}}
            >Subscribe</Button>

        </div>
      </form>
      </center>
    </div>
  );
}

export default SubscribeForm;