import './App.css';
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar, Container } from "react-bootstrap";
import Home from './pages/Home';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';


function App() {
  // send pageview to Google Analytics only when location (path) changes
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-BCLW59284D");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location])


  return (
    <>
      <Container>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      </Container>
  
    </>
    
  );
}

export default App;
