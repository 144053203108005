import React, { useState, useEffect } from "react";
import { Alert, Container, Card, Button, Image } from "react-bootstrap";
import SubscribeForm from "../Components/SubscribeForm";
import CreatedBy from "../Components/CreatedBy";
import ReactGA from "react-ga4";
import "./Home.css";
import logo192 from "../images/logo192.png";

// import axios
// import axios from 'axios';
// import { Container, Row, Col, Card } from 'react-bootstrap';

// fetch request to /api/news endpoint
// axios.get('/api/news')
//   .then(response => {
//     console.log("RESPONSE: ", response.data);
//     news = response.data;
//   })

function Home() {
  // create a state variable to hold the news data
  const [news, setNews] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // const fetchNextPage = () => {
  //   // fetch data for the next page here
  //   fetch('/api/news/' + page + '/')
  //     .then(response => response.json())
  //     .then((data) => {
  //       setNews(data);
  //       console.log("DATA: ", data);
  //       setPage(page + 1);
  //     }
  //       )
  //     .catch(error => console.error(error));
  // };

  async function fetchNextPage() {
    setIsLoading(true);
    if (page > 1) {
      ReactGA.event({
        category: "news",
        action: "load_more_news",
        value: page,
      });
    }
    try {
      const response = await fetch("/api/news/" + page + "/");
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(data.error);
      }
      if (!news) {
        setNews(data);
      } else {
        setNews((prevNews) => [...prevNews, ...data]);
      }
      setPage(page + 1);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchNextPage();
  }, []);

  // get current date in Weekday, Month Day, Year format
  const currentDate = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Container style={{ maxWidth: "700px" }}>
      <center>
        <AlertDismissibleExample />
        
        <h1 className="mt-5" id="headlineContainer">
          <Image src={logo192} className="mb-3" id="headlineLogo" />{" "}
          <span id="headline">ShitFilter.News</span>{" "}
        </h1>
        <h4 style={{ marginTop: "0px" }}>
          Important trends in news filtered and explained by AI
        </h4>
        <h6 className="pt-1">{currentDate}</h6>
      </center>
      <CreatedBy />
      {news ? (
        <>
          {news.map((article, index) => (
            <>
              <hr />
              <Card key={index} style={{ marginTop: "20px", border: "none" }}>
                <Card.Body className="px-0">
                  <Card.Title as="h3" className="mb-3">
                    {article.larger_trend}
                  </Card.Title>
                  <Card.Subtitle className="mb-2">
                    {article.article_title}
                  </Card.Subtitle>
                  <Card.Text>
                    <a
                      href={article.url_of_article}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {article.url_of_article.substring(0, 200)}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {article.short_explanation_of_larger_trend}
                  </Card.Text>
                  <Card.Text>{article.contrarian_view}</Card.Text>

                  <Card.Title>Possible incentives of participants</Card.Title>
                  <ul>
                    {Object.keys(
                      article.explanation_of_incentives_of_participants
                    ).map((key, index) => (
                      <li key={index}>
                        <b>{key}:</b>{" "}
                        {article.explanation_of_incentives_of_participants[key]}
                      </li>
                    ))}
                  </ul>

                  <Card.Title>Possible second-order effects</Card.Title>
                  <ul>
                    {article.possible_second_order_effects.map(
                      (effect, index) => (
                        <li key={index}>{effect}</li>
                      )
                    )}
                  </ul>
                </Card.Body>
              </Card>
            </>
          ))}
          {/* Create container with width to maxwidth and justify center content  */}
          <Container className="justify-content-center d-flex">
            <Button
              onClick={fetchNextPage}
              id="loadMore"
              variant="outline-primary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Load more news"}
            </Button>
          </Container>
        </>
      ) : (
        <Container className="justify-content-center d-flex">
          <p>Loading news...</p>
        </Container>
      )}
      {/* check if there is an error */}
      {error && (
        <Container className="justify-content-center d-flex">
          <p className="pt-3">{error}</p>
        </Container>
      )}

      <hr className="mt-5" />
      {/* create navbar and center content */}
      <Container className="justify-content-center d-flex flex-column pb-5">
        <SubscribeForm />
      </Container>
    </Container>
  );
}

function AlertDismissibleExample() {
  const [show, setShow] = useState(true);

  const [showText, setShowText] = useState(true);
  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(false);
      setShowBackground(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (show) {
    return (
      <Alert
        variant="light"
        className="mt-4"
        style={{ border: "1px gray solid" }}
        onClose={() => setShow(false)}
        dismissible
      >
        <Card className="border-0">
          {/* <Card.Img variant="top" src={logo192} /> */}
          <Card.Body className="mx-1">
            <Card.Title
              style={{ fontWeight: "normal", fontSize: "2.4em" }}
              className="my-4"
            >
              Learn your{" "}
              <span style={{ color: "#58cc02", fontWeight: "bold" }}>
                duolingo
              </span>{" "}
              vocabulary while you browse the web
            </Card.Title>
            <Card.Text>
              Use the <a href="https://duolingoninja.com">Duolingo Ninja</a>{" "}
              browser extension to translate words and sentences on web pages
              you visit{" "}
              <mark
                className={`fade-in-background ${
                  showBackground ? "green-background" : ""
                }`}
                style={{
                  backgroundColor: showBackground
                    ? "rgba(137, 226, 25, 0.4)"
                    : "white",
                }}
              >
                {showText ? "automatic" : "automáticamente"}
              </mark>
              .
            </Card.Text>
            <Button
              variant="success"
              className="my-2"
              href="https://duolingoninja.com"
            >
              Try the free version
            </Button>
          </Card.Body>
        </Card>
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)} variant="light">Show ad</Button>;
}

export default Home;
